.QcSidebarsupport{
    display: flex;
    align-items: center;
    gap: 5px;
}

@media (max-width: 1300px) {
    .QcSidebarsupport {
      display: inline;
    }
  }
  