.NewAdmin{
      height: 36px;
      width: 15%;
      border-radius: 4px;
      color: white;
      font-size: 12px;
      font-family: Poppins;
      font-weight: 400;
      background-color:#FEC83B;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

}
.search{
    height: 36px;
    width: 85%;
    border-radius: 4px;
      color: white;
      font-size: 12px;
      font-family: Poppins;
      font-weight: 400;
      background-color:#fff;
      display: flex;
      align-items: center;
      justify-content: center;
     
}

.searchInput{
    width: 100%;
    height: 90%;
    background: transparent;
    text-align: left;
    outline: none;
    border: none;
}

.tableheading{
    color:#2C3A52;
    font-size: 14px;
    font-family: 600;
    font-family: poppins;
}
.tablecolum{
    color:#2C3A52;
    font-size: 14px;
    font-family: 400;
    font-family: poppins;
}

.blockbutton{
   background-color: #d46653;
   width: 77px;
   height: 36px;
   border-radius: 4px;
   border: none;
   color:white;
   font-size: 12px;
   font-family: 400;
   font-family: poppins;
}
.unblockbutton{
  background-color: #DBE8FF;
  width: 77px;
  height: 36px;
  border-radius: 4px;
  border: none;
  color: #2C3A52;
  font-size: 12px;
  font-family: 400;
  font-family: poppins;
}

/* Container */
.roleeditorcontainer {
    width: 664px;
    /* height: 483px; */
    border-radius: 8px;
    /* margin: 0 auto; */
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    font-family: Arial, sans-serif;
  }
  
  /* Header */
  .roleeditorcontainer h2 {
    text-align: center;
    margin-bottom: 20px;
    color: #333;
    text-align: left;
    font-weight: 500;
    font-family: Poppins;
  }
  
  /* Form Group */
  .formgroup {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  
  .formgroup label {
    font-size: 14px;
    color: #333;
  }
  
  .formgroup select {
    flex-grow: 1;
    margin-left: 10px;
    padding: 8px;
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .createrolebtn {
    margin-left: 10px;
    padding: 8px 12px;
    background-color: #fdb02a;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .createrolebtn:hover {
    background-color: #e19a1c;
  }
  
  /* Responsibilities */
  .responsibilities h4 {
    margin-bottom: 10px;
    color: #333;
  }
  
  .checkboxgroup {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .checkboxgroup label {
    margin-left: 8px;
    font-size: 14px;
    color: #333;
  }
  
  /* Save Button */
  .savebtn {
    position: relative;
    width: 20%;
    padding: 10px 0;
    background-color: #2a445e;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    left: 40%;
    right: 40%;
  }
  
  .savebtn:hover {
    background-color: #1f3346;
  }
  
  .newrolefrom{
    color: #5C5C5C;
    font-size: 14px;
    font-weight: 500;
    font-family: 'Poppins';
  }