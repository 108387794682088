.Main {
    display: flex;
    width: 100%;
    height: 100%;
}

.Div1 {
    border-radius: 4px;
    background-color: #fbfaf6;
    min-width: 50%;
    min-height: 100vh;
    padding-left: 80px;
}

.Image {
    width: 400px;
}


.Div2 {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 50%;

}

.Div3 {

    width: 100%;
    /* background-color: bisque; */
    position: relative;
    width: 100%;
    margin: 10%;

}

.Div4 {
    position: relative;
    margin-top: 50px;
    display: flex;
    align-items: center;
    gap: 20px;
}

.Options {
    display: flex;
    width: 80%;
    justify-content: space-evenly;
    gap: 10px;
}

.Form {
    position: relative;
    width: 100%;
    margin-top: 20px;
}

.Div5 {
    position: relative;
    display: flex;
    align-items: center;
}

.welcometext {
    font-size: 28px;
    line-height: 42px;
    font-weight: 600;
    font-family: Poppins;
    color: #000;
}

.logintext {
    font-size: 16px;
    line-height: 26px;
    font-family: Poppins;
    color: #707070;
}

.Div6 {
    position: relative;
    margin-top: 5%;
    width: 100%;
    display: flex;
    align-items: center;
}

.loginoptiontext {
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    font-family: Poppins;
    color: #233a48;
}

.radionButton {
    width: 24px;
    position: relative;
    height: 24px;

}

.radionLabel {
    position: relative;
    font-size: 16px;
    line-height: 24px;
    font-family: Inter;
    color: #5c5c5c;
}

.OptionDiv {
    display: flex;
    justify-content: space-evenly;
    width: 60%;
    left: 12%;
    position: relative;

}

.Div7 {
    height: 52px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.googletext {
    font-size: 16px;
    line-height: 24px;
    text-transform: capitalize;
    font-weight: 500;
    font-family: Poppins;
    color: #233a48;
    height: 24px;
}

.Div8 {
    background-color: white;
    display: flex;
    justify-content: space-evenly;
}

.SperaterText {
    position: relative;
    font-size: 14px;
    line-height: 21px;
    font-family: Poppins;
    color: #707070;
    text-align: center;
    width: 112.6px;
    height: 21px;
}

.horizontalDivider {
    width: 180px;
    position: relative;
    border-top: 1px #EAEAEA;
    box-sizing: border-box;
    height: 10px;
}

.Div9 {
    position: relative;
    margin-top: 5%;
}

.Div10 {
    position: relative;
    margin-top: 5%;
}

.EmailText {
    /* width: 56px; */
    position: relative;
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    font-family: Poppins;

    height: 24px;
    color: #233a48;
}

.EmailBackground {
    width: 100%;
    position: relative;
    border-radius: 4px;
    background-color: #f8f8f8;
    border: 1px #f1f1f1;
    box-sizing: border-box;
    height: 51px;
    text-align: left;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.2);
    font-family: Poppins;
    display: flex;
    align-items: center;
    border-style: none;
    overflow: hidden;
    outline: none;

}

.Password {
    display: flex;
    background-color: #f8f8f8;
    border: 1px #f1f1f1;
    box-sizing: border-box;
    height: 51px;
    align-items: center;
}

.Showpassword {
    position: relative;
    right: 5%;
}

.Div11 {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 2%;
}

.rememberMe {
    position: relative;
    font-size: 16px;
    line-height: 26px;
    font-family: Poppins;
    color: #707070;
    
}

.forgotPassword {
    position: relative;
    font-size: 16px;
    line-height: 26px;
    font-family: Poppins;
    color: #fec83b;
}

.Loginbutton {
    width: 75%;
    position: relative;
    border-radius: 4px;
    background-color: #fec83b;
    height: 52px;
    overflow: hidden;
    flex-shrink: 0;
    text-align: center;
    font-size: 16px;
    color: #fff;
    font-family: Poppins;
    border-style: none;
}


.InputDiv {
    width: 100%;
    position: relative;
    border-radius: 4px;
    background-color: #f8f8f8;
    border: 1px solid #f1f1f1;
    box-sizing: border-box;
    height: 51px;
    text-align: left;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.2);
    font-family: Poppins;
    display: flex;
    flex-direction: row;
    align-items: center;

}

@media only screen and (max-width: 999px){
    .Main {
        display: block;
        width: 100%;
        height: 100%;
    }
    .Div1 {
        border-radius: 4px;
        background-color: #fbfaf6;
        min-width: 50%;
       display: flex;
       align-items: center;
       justify-content: space-evenly;
    }
    .Div2 {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        height: 100%;
        width: 100%;
    
    }
    .Image {
        width: 80%;
    }
    

}