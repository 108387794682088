.MainDiv {
    position: relative;
    margin: 6%;

    /* height: 100vh; */

}

.Div1 {

    position: relative;
    height: 58px;
    align-items: center;
    display: flex;
    justify-content: space-between;
}

.Div2 {
    position: relative;
    margin-top: 15px;
    width: 100%;

    display: flex;
    align-items: center;


}

.ProfileName {
    position: relative;
    font-size: 16px;
    font-family: Poppins;
    color: #424242;
    width: 119px;
}

.SuperAdmin {
    width: 100%;
    position: relative;
    border-radius: 2px;
    background-color: #fec83b;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 2px 16px;
    box-sizing: border-box;
    text-align: left;
    font-size: 12px;
    color: #fff;
    font-family: Poppins;

}

.Div3 {
    width: 100%;
    position: relative;
    border-radius: 4px;
    background-color: #fec83b;
    height: 48px;
    margin-top: 15px;
    display: flex;
    align-items: center;

}

.Dashboardtext {
    position: relative;
    font-size: 16px;
    font-weight: 500;
    font-family: 'Poppins';
    color: #fff;
    width: 40%;
    position: relative;
    border-radius: 4px;
    /* background-color: #fec83b; */
    height: 48px;
    margin-top: 15px;
    display: flex;
    align-items: center;
    
}



.Div4 {
    width: 100%;
    position: relative;
    border-radius: 4px;
    height: 48px;
    margin-top: 5px;
    display: flex;
    align-items: center;
    position: relative;
    font-size: 16px;
    font-weight: 500;
    font-family: Poppins;
    color: #424242;
    /* margin-left: 5px; */
    border-style: none;
    background: transparent;

}

.listText {
    position: relative;
    font-size: 16px;
    font-weight: 500;
    font-family: Poppins;
    color: #424242;
    margin-left: 5px;
}

.Div5 {
    position: relative;

    width: 100%;
}

.Logout {


    margin: 6%;

    bottom: 20px;
    align-items: center;
    display: flex;
    font-size: 16px;
    font-family: Poppins;
    color: #424242;
    height: 38px;
    position: relative;
    border-radius: 4px;
    border: 1px solid #424242;
    position: absolute;
    width: 80%;
    padding-left: 5%;


}

@media only screen and (max-width:1100px){
    .Div3 {
        width: 70%;
        position: relative;
        border-radius: 4px;
        background-color: #fec83b;
        height: 48px;
        margin-top: 15px;
        display: flex;
        align-items: center;
    
    }
}