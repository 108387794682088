.MainDiv {
    width: 100vw;
    height: 100vh;
    /* background-color: aquamarine; */
    position: relative;
    display: flex;
}

.SideBar {
    width: 20vw;
    height: 100vh;
    /* background-color: burlywood; */
    position: relative;

}

.Dashboard {
    position: relative;
    width: 80vw;
    height: 100vh;
    background-color: #f5f5f5;
    padding: 15px;

}

.headrBox {
    position: relative;
    width: 100%;
    /* background-color: forestgreen; */
    height: 120px;
    margin-top: 12px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 100%;


}

.Boxvalue {
    font-size: 48px;
    font-weight: 500;
    font-family: Poppins;
    color: #223a48;
}

.Box {
    position: relative;
    border-radius: 8px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    /* padding: 7px 38px; */
    box-sizing: border-box;
    gap: 8px;
    width: 200px;
}

.Boxlabel {
    font-size: 14px;
    font-weight: 600;
    font-family: Poppins;
    color: #424242;
}

.Div2 {
    position: relative;
    display: flex;
    align-items: center;
    /* background-color: azure; */
    justify-content: space-around;
}

.Div3 {
    position: relative;
    width: 25%;
    display: flex;
    align-items: center;
    position: relative;
    border-radius: 8px;
    background-color: #fff;
    border: 1px solid #f2f2f2;
    box-sizing: border-box;
    /* width: 100%; */
    height: 38px;
    overflow: hidden;
    gap: '4px';
    justify-content: space-evenly;
}

.Div3text {
    position: relative;
    font-size: 12px;
    font-weight: 500;
    font-family: Poppins;
    color: #333;
}

.Div4 {
    position: relative;
    border-radius: 4px;
    background-color: #fff;
    border: 2px solid #dedede;
    box-sizing: border-box;
    height: 40px;
    overflow: hidden;
    width: 25%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.Div4text {
    position: relative;
    font-size: 12px;
    font-family: Poppins;
    color: #5c5c5c;
}

.Div5 {
    position: relative;
    border-radius: 4px;
    background-color: #fff;
    border: 2px solid #dedede;
    box-sizing: border-box;
    height: 40px;
    overflow: hidden;
    flex-shrink: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 10px;
    text-align: left;
    font-size: 12px;
    color: #5c5c5c;
    font-family: Poppins;
    width: 40%;
}

.Div6 {
    position: relative;
    border-radius: 8px;
    background-color: #fff;
    height: fit-content;
    margin: 5px;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    /* gap: 26px; */
}

.Div7 {
    position: relative;
    /* width: 100%; */
    /* background-color: cadetblue; */
    margin: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.Profilename {
    position: relative;
    font-size: 14px;
    font-family: Poppins;
    color: #424242;
}

.TeamName {
    position: relative;
    font-size: 12px;
    font-weight: 500;
    font-family: Poppins;
    color: #a9a9a9;

}

.Div8 {
    position: relative;
    font-size: 12px;
    font-weight: 500;
    font-family: Poppins;
    color: #333;
}

.Div9 {
    position: relative;
    /* background-color: cornsilk; */
    margin: 10px;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.DIv10 {
    position: relative;
    font-size: 14px;
    font-weight: 500;
    font-family: Poppins;
    color: #5c5c5c;
}

.Div11 {
    position: relative;
    font-size: 12px;
    font-weight: 600;
    font-family: Poppins;
    color: #a9a9a9;
}

.lineDiv {
    /* width: 100%; */
    position: relative;
    border-right: 1px solid #a9a9a9;
    box-sizing: border-box;
    height: 48px;
}


@media only screen and (max-width:1100px){
    .Box {
        position: relative;
        border-radius: 8px;
        background-color: #fff;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        /* padding: 7px 38px; */
        box-sizing: border-box;
        gap: 8px;
        width: 150px;
    }
    .Div2 {
        position: relative;
        display: block;
        align-items: center;
        /* background-color: azure; */
        justify-content: space-evenly;
    }
    .Div3 {
        position: relative;
        width: 100%;
        display: flex;
        align-items: center;
        position: relative;
        border-radius: 8px;
        background-color: #fff;
        border: 1px solid #f2f2f2;
        box-sizing: border-box;
        /* width: 100%; */
        height: 38px;
        overflow: hidden;
        gap: '4px';
        justify-content: space-between;
    }
    .Div4 {
        position: relative;
        border-radius: 4px;
        background-color: #fff;
        border: 2px solid #dedede;
        box-sizing: border-box;
        height: 40px;
        overflow: hidden;
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
       
    }

    .Div5 {
        position: relative;
        border-radius: 4px;
        background-color: #fff;
        border: 2px solid #dedede;
        box-sizing: border-box;
        height: 40px;
        overflow: hidden;
        flex-shrink: 0;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        padding: 10px;
        text-align: left;
        font-size: 12px;
        color: #5c5c5c;
        font-family: Poppins;
        width: 100%;
    }
    

}

@media only screen and (max-width:850px){
    .Box {
        position: relative;
        border-radius: 8px;
        background-color: #fff;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        /* padding: 7px 38px; */
        box-sizing: border-box;
        gap: 8px;
        width: 20%;
    }
    
    .Div7 {
        position: relative;
        width: 100%;
        /* background-color: cadetblue; */
        margin: 10px;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
    }
    .Div9 {
        position: relative;
        /* background-color: cornsilk; */
        margin: 10px;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        flex-direction: column;
    }
    .Boxvalue {
        font-size: 28px;
        font-weight: 500;
        font-family: Poppins;
        color: #223a48;
    }
    .Boxlabel {
        font-size: 10px;
        font-weight: 600;
        font-family: Poppins;
        color: #424242;
    }

}


.Div2Box {
    width: 100%;
    position: relative;
    border-radius: 4px;
    background-color: #fff;
    border: 1px solid #424242;
    box-sizing: border-box;
    height: 44px;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    outline: none;
    position: relative;
    font-size: 12px;
    font-family: Poppins;
    color: #5c5c5c;
}

.dropdown {
    width: 200px;
    position: relative;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    text-align: left;
    font-size: 14px;
    color: #424242;
    font-family: Poppins;
}


