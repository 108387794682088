.Main{
    display: flex;
     width: 100%;
      height: 100%;
}
.FormDivMain{
    position: relative;
     width: 60%;
}
.Singup {

    /* position: relative; */
    border-radius: 4px;
    background-color: #fbfaf6;
    min-width: 50%;
    min-height: 100vh;
    padding-left: 80px;

}
.Image{
    width: 400px;
}

.Div2 {

    min-width: 60%;
    min-height: 100vh;
    /* background-color: brown */

}

.Form1 {
    background-color: aqua;
}

.heading {


    position: relative;
    font-size: 28px;
    line-height: 42px;
    font-weight: 600;
    font-family: Poppins;
    color: #000;
    text-align: left;
    display: inline-block;



}

.registerToLaw {
    width: 100%;
    position: relative;
    font-size: 16px;
    line-height: 26px;
    font-family: Poppins;
    color: #707070;
    text-align: left;
    display: inline-block;
}



.googlebutton {
    width: 100%;
    position: relative;
    border-radius: 4px;
    background-color: #f9f9f9;
    height: 52px;
    overflow: hidden;
    text-align: center;
    font-size: 16px;
    color: #233a48;
    font-family: Poppins;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
}

.googleIcon {
    position: relative;
    /* top: 16px; */
    /* left: 255px; */
    width: 20px;
    height: 20px;
    object-fit: cover;
    top: 30%;
}

.googletext {
    position: relative;
   
    line-height: 24px;
    text-transform: capitalize;
    font-weight: 500;
    
    height: 24px;
    
}

.separator {
    width: 100%;
    position: relative;
    height: 21px;
    text-align: center;
    font-size: 14px;
    color: #707070;
    font-family: Poppins;
    left: 33%;
    /* background-color: rgb(230, 221, 228); */
}

.horizontalDivider {
    position: relative;
    width: 30%;
    top: 10px;
    left: -30%;
    border-top: 1px #eaeaea;
    box-sizing: border-box;
    height: 1px;
}

.heading6 {
    position: relative;
    top: -10px;
    left: 38%;
    line-height: 21px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 112.6px;
    height: 21px;
}

.horizontalDivider1 {
    position: relative;
    width: 30%;
    top: -27px;
    right: -35%;

    border-top: 1px #eaeaea;
    box-sizing: border-box;
    height: 1px;
}

.singupform {
    /* width: 150%; */
    position: relative;
    height: 448px;
    text-align: left;
    font-size: 16px;
    color: #233a48;
    font-family: Poppins;
}

.label {
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    font-family: Poppins;
    /* width: 97.8px; */
    position: relative;
    color: #233a48;
}

.span {
    color: #cf0909;
}

.input {
    position: relative;
    border-radius: 4px;
    background-color: #f8f8f8;
    border: 1px #f1f1f1;
    box-sizing: border-box;
    height: 51px;
    text-align: left;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.2);
    font-family: Poppins;
    display: flex;
    align-items: center;

}

.emailinput {
    align-items: center;
    width: 100%;
    height: 41px;
    overflow: hidden;
    opacity: 0.42;
    border: none;
    background-color: #dad9d9af;
}

.accept {
   
    position: relative;
    font-size: 16px;
    line-height: 26px;
    font-family: Poppins;
    color: #707070;
    /* margin-left: 5px; */
    height: 26px;
}

.termsConditions {
    
    position: relative;
    font-size: 14px;
    line-height: 21px;
    font-weight: 500;
    font-family: Poppins;
    color: #233a48;


    height: 21px;
}

.proceedbutton {
    width: 30%;
    position: relative;
    border-radius: 4px;
    background-color: #fec83b;
    height: 52px;
    overflow: hidden;
    text-align: center;
    font-size: 16px;
    color: #fff;
    font-family: Poppins;
    border: none;
    display: flex;
       align-items: center;
    justify-content: center;
}

.proceedtext {
    width: 66.4px;
    position: relative;
    font-size: 16px;
    line-height: 24px;
    text-transform: capitalize;
    font-weight: 500;
    font-family: Poppins;
    color: #fff;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 24px;
    margin-top: 10px;
}

.alreadyAMember {
    position: relative;
    font-size: 14px;
    line-height: 21px;
    font-family: Poppins;
    color: #707070;

    height: 21px;

}

.login {
    
    position: relative;
    font-size: 14px;
    line-height: 21px;
    font-weight: 500;
    font-family: Poppins;
    color: #233a48;

    height: 21px;
    
}

/* 900 */

@media only screen and (max-width: 990px)  {
    .Singup {

        position: relative;
        border-radius: 4px;
        background-color: #fbfaf6;
       display: flex;
       align-items: center;
       justify-content: center;
    
    }
    .Main{
        position: relative;
        display: block;
        width: 100%;
        height: 100%;
    }
    .FormDivMain{
        position: relative;
         width: 100%;
         display: flex;
         align-items: center;
         justify-content: space-around;
    }
    .Image{
        width: 80%;
    }
  }

  @media only screen and (max-width: 500px){
    .Image{
        width: 100%;
        margin-right: 25%;
    }
  }