.MainDiv {
    position: relative;
    width: 100%;
    height: 100%;
    width: 100%;
    display: flex;
    /* align-items: center; */
    /* justify-content: center; */
}

.SideBar {
    width: 20%;
    position: relative;
}

.List {
    width: 80%;
    padding: 5px;
    position: relative;
    background-color: #f5f5f5;
    height: 100%;

}

.Div2Box {
    /* width: 100%; */
    position: relative;
    border-radius: 4px;
    background-color: #fff;
    border: 1px solid #424242;
    box-sizing: border-box;
    height: 44px;
    display: flex;
    align-items: space-between;
    flex-direction: row;
    justify-content: space-between;
    outline: none;
    position: relative;
    font-size: 14px;
    font-family: Poppins;
    color: #5c5c5c;
}