.TableHeading {
    font-size: 14px;
    font-weight: 600;
    font-family: Poppins;
    color: #424242;
}

.TableRow {
    position: relative;
    font-size: 14px;
    font-weight: 500;
    font-family: 'Poppins';
    color: #5c5c5c;
}