.MainDiv {
    position: relative;
    height: 100%;
    width: 100%;
    display: flex;
    /* align-items: center; */
    /* justify-content: center; */
}

.SideBar {
    width: 20%;
    position: relative;
    /* background-color: #223a48; */
}

.Dashboard {
    width: 80%;
    position: relative;
    background-color: #f5f5f5;
    height: 100vh;

}

.Div1 {
    position: relative;
    margin: 15px;
}

.BoxValue {
    width: 100%;
    position: relative;
    font-size: 48px;
    font-weight: 500;
    font-family: 'Poppins', sans-serif;
    color: #223a48;
    text-align: center;
    display: inline-block;
    overflow: hidden;
}

.BoxLabel {
    position: relative;
    font-size: 14px;
    font-weight: 600;
    font-family: 'Poppins',;
    color: #424242;
}

.Div2 {
    position: relative;
    margin: 15px;
    display: flex;
    align-items: center;
    /* justify-content: space-evenly; */
    gap: 20px;
}

.Div3 {
    position: relative;
    border-radius: 8px;
    background-color: #fff;
    border: 1px solid #f2f2f2;
    box-sizing: border-box;
    /* width: 100%; */
    height: 38px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 10px;
    gap: 4px;
    cursor: pointer;
    text-align: left;
    font-size: 12px;
    color: #333;
    font-family: 'Poppins',;
}

.Div4 {
    position: relative;
    margin: 15px;
    border-radius: 8px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 8px 16px;
    box-sizing: border-box;
    gap: 26px;
    text-align: left;
    font-size: 14px;
    color: #424242;
    font-family: 'Poppins',;


}