.MainDIv {
    position: relative;
    display: flex;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    
    /* padding: 10px */

}

.Sidebar {
    position: relative;
    width: 25%;
    height: 100vh;
}

.Adminteam {
    position: relative;
   
    width: 100%;
    height: 100vh;
    background-color:#f5f5f5;
     padding: "10px";
     overflow: scroll;
     overflow-x: hidden;
}

.Div1 {
    position: relative;
    border-radius: 8px;
    background-color: #fff;
    position: relative;
    margin: 10px;
}

.Div1text {
    position: relative;
    font-size: 16px;
    font-weight: 500;
    font-family: 'Poppins';
    color: #424242;
}

.Divlabel {
    position: relative;
    font-size: 12px;
    font-weight: 500;
    font-family: Poppins;
    color: #424242;
}

.Div1Input {


    width: 100%;
    position: relative;
    border-radius: 4px;
    background-color: #fff;
    border: 1px solid #424242;
    box-sizing: border-box;
    height: 44px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 10px 16px;
    cursor: pointer;
    position: relative;
    font-size: 14px;
    font-family: Poppins;
    color: #5c5c5c;



}

.Div2 {
    position: relative;
    /* background-color: rgb(38, 87, 49); */
    width: 100%;

}

.Div2text {
    width: 100%;
    position: relative;
    font-size: 12px;
    font-weight: 500;
    font-family: Poppins;
    color: #424242;
}

.Div2Boxtext {
    position: relative;
    font-size: 14px;
    font-family: 'Poppins';
    color: #5c5c5c;
}

.Div2Box {
    /* width: 100%; */
    position: relative;
    border-radius: 4px;
    background-color: #fff;
    border: 1px solid #424242;
    box-sizing: border-box;
    height: 44px;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    outline: none;
    position: relative;
    font-size: 14px;
    font-family: Poppins;
    color: #5c5c5c;
}

.Div2box2 {
    /* width: 40px; */
    background-color: #f5f5f5;
    border: 1px solid #424242;
    border-left: none;
    box-sizing: border-box;
    height: 44px;
    position: relative;
    font-size: 14px;
    font-family: Poppins;
    color: #5c5c5c;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    text-align: left;
}

.TableDiv {
    position: relative;
    border-radius: 8px;
    background-color: #fff;
    position: relative;
    margin: 10px;

}

.TableDiv1 {
    position: relative;
    border-radius: 4px 4px 0px 0px;
    background-color: #f5f5f5;
    border-bottom: 1px #223a48;
    box-sizing: border-box;
    /* width: 100%; */
    height: 37px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 10px 20px;
    text-align: left;
    font-size: 14px;
    color: #223a48;
    font-family: Poppins;
}

.TableAllmemebartext {


    font-size: 14px;
    font: 500;
    font-family: Poppins;
    color: #223a48;
    margin-left: 20px;

}


.TableSearchbox {
    position: relative;
    border-radius: 4px;
    background-color: #fff;
    border: 2px solid #dedede;
    box-sizing: border-box;
    height: 40px;
    overflow: hidden;
    width: 100%;
    display: flex;
    align-items: center;
    padding-left: 10px;
    justify-content: space-between;
}

.TableAddQc {
    position: relative;
    border-radius: 4px;
    background-color: #223a48;
    border: 1px #223a48;
    box-sizing: border-box;
    /* width: 100%; */
    height: 38px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 10px 20px;
    cursor: pointer;
    text-align: left;
    font-size: 14px;
    color: #fff;
    font-family: Poppins;
}

.TableHeader {


    font-size: 14px;
    color: #424242;
    font-family: 'Poppins';
}

.dropdown {
    width: 200px;
    position: relative;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    text-align: left;
    font-size: 14px;
    color: #424242;
    font-family: Poppins;
}


.dropdown2 {
    width: 300px;
    position: relative;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    text-align: left;
    font-size: 14px;
    color: #424242;
    font-family: 'Poppins';
    position: absolute;
    top: 0;
    left: 300px;
};

@media only screen and (min-width: 1600px) {
    .InternsDropdown {
      width: 20%;
      
    }
  }
  